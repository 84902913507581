import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'
import { BuilderTheme } from '../ThemeProvider/themes'

export const createStyles = (theme: BuilderTheme) => 
    StyleSheet.create({
        text: {
            alignSelf: 'center',
            fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
            lineHeight: theme?.typography?.size?.base?.lineHeight ?? 24,
            fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
            color: theme?.colors?.text ?? COLORS.COLOR_NEUTRAL_GRAY,
        },
        icon: {
            width: 20,
            height: 20,
            marginHorizontal: theme?.spacing?.margin ?? 7,
        },
        button: {
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            paddingHorizontal: theme?.spacing?.padding ?? 10,
            height: 44,
            display: 'flex',
            backgroundColor: theme?.colors?.background ?? 'transparent',
        },
        disable: {
            backgroundColor: theme?.colors?.disabled ?? COLORS.COLOR_BTNGRAY,
        },
        innerView: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            flexGrow: 1,
        },
        gradient: {
            flexGrow: 1,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            width: null,
            height: null,
        },
        activityIndicator: {
            paddingLeft: theme?.spacing?.padding ?? 7
        }
    })

